"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_components_1 = require("@grandlinex/react-components");
class SchemaUtil {
    static fieldsFromObject(schema, out, path = []) {
        for (const key in schema.properties) {
            if (key) {
                const prop = schema.properties[key];
                switch (prop.type) {
                    case 'object':
                        this.fieldsFromObject(prop, out, [...path, key]);
                        break;
                    case 'array':
                    default:
                        out.set([...path, key].join('.'), {
                            type: prop.type,
                            t: prop.t,
                            t_de: prop.t_de,
                            field: key,
                        });
                }
            }
        }
    }
    static fieldsFromSchema(schema) {
        const outMap = new react_components_1.CMap();
        if (schema.type !== 'object') {
            return outMap;
        }
        this.fieldsFromObject(schema, outMap);
        return outMap;
    }
    static dataFromSchemaPath(schema, data, de) {
        const parts = schema.split('.');
        let out = data;
        while (parts.length > 0 && !!out) {
            const part = parts.shift();
            if (parts.length === 0 && part && de) {
                out = out[`${part}_de`] || out[part];
            }
            else if (part) {
                out = out[part];
            }
            else {
                out = undefined;
            }
        }
        if (typeof out === 'object') {
            if (Array.isArray(out)) {
                return out.join(', ');
            }
            return '';
        }
        return out;
    }
    static schemaFromObject(obj) {
        if (obj === null) {
            return { type: 'null' };
        }
        if (Array.isArray(obj)) {
            const itemsSchema = obj.length > 0 ? this.schemaFromObject(obj[0]) : { type: 'any' };
            return {
                type: 'array',
                items: itemsSchema,
            };
        }
        switch (typeof obj) {
            case 'string':
                return { type: 'string' };
            case 'number':
                return { type: 'number' };
            case 'boolean':
                return { type: 'boolean' };
            case 'object': {
                const properties = {};
                const required = [];
                for (const [key, value] of Object.entries(obj)) {
                    properties[key] = this.schemaFromObject(value);
                    required.push(key);
                }
                return {
                    type: 'object',
                    properties,
                    required,
                };
            }
            default:
                return { type: 'any' };
        }
    }
    static isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
}
exports.default = SchemaUtil;
