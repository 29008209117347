"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseJwt = parseJwt;
exports.parseJwtLegacy = parseJwtLegacy;
function parseJwt(token) {
    try {
        const base64Payload = token.split('.')[1];
        const payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }
    catch (e) {
        return null;
    }
}
function parseJwtLegacy(token) {
    try {
        const base64Payload = token.split('.')[1];
        const payload = atob(base64Payload);
        return JSON.parse(payload.toString());
    }
    catch (e) {
        return null;
    }
}
