"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticRoutingOptions = void 0;
exports.StaticRoutingOptions = [
    {
        key: 'home',
        labelKey: 'header.home',
    },
    {
        key: 'news',
        labelKey: 'header.news',
        sub: [
            {
                key: 'general',
                labelKey: 'header.news.general',
            },
            {
                key: 'offtopic',
                labelKey: 'header.news.offtopic',
            },
            {
                key: 'guides',
                labelKey: 'header.news.guides',
            },
        ],
    },
    {
        key: 'db',
        labelKey: 'header.db',
        sub: [
            {
                key: 'res',
                labelKey: 'header.res',
            },
            {
                key: 'map',
                labelKey: 'header.map',
            },
        ],
    },
    {
        key: 'about',
        labelKey: 'header.about',
    },
    {
        key: 'admin',
        labelKey: 'header.admin',
        require: {
            user: true,
            admin: true,
        },
        hideOnMobile: true,
        sub: [
            {
                key: 'admin-news',
                labelKey: 'header.admin.news',
            },
            {
                key: 'admin-media',
                labelKey: 'header.admin.media',
            },
            {
                key: 'admin-resource',
                labelKey: 'header.admin.resource',
                require: {
                    admin: true,
                },
            },
            {
                key: 'admin-resource-icon',
                labelKey: 'header.admin.resource.icon',
                require: {
                    admin: true,
                },
            },
            {
                key: 'admin-map-pin',
                labelKey: 'header.admin.map-pin',
                require: {
                    admin: true,
                },
            },
        ],
    },
    {
        key: 'github',
        labelKey: 'header.github',
        external: {
            link: 'https://github.com/Elschnagoo/',
            ico: '/static/github-mark-white.svg',
        },
    },
];
