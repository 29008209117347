"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransFc = TransFc;
exports.default = TransComp;
exports.useTransComp = useTransComp;
const react_1 = require("react");
const store_1 = require("@/store");
const XLang_1 = require("@/component/map/XLang");
function TransFc({ sKey, el, children, fallback, t, }) {
    return (0, XLang_1.TransFcRaw)({
        sKey,
        el,
        children,
        fallback,
        langCode: t.code,
    });
}
function TransComp({ el, sKey, children, fallback, }) {
    const t = (0, store_1.useTranslation)();
    return TransFc({ sKey, el, children, fallback, t });
}
function useTransComp(langCode, force) {
    const t = (0, store_1.useTranslation)();
    return (0, react_1.useCallback)((el, sKey) => (0, XLang_1.TransFcRaw)({ el, sKey, langCode: langCode || t.code, force }), [force, langCode, t.code]);
}
