"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = redirect;
const react_components_1 = require("@grandlinex/react-components");
function redirect(app, red) {
    const base = `${(0, react_components_1.getDocumentMeta)('REACT_2SPB_API')}/api/app/redirect/`;
    if (red) {
        return `${base}${app}?redirect=${red}`;
    }
    return base + app;
}
