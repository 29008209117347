"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticURL = void 0;
exports.useResolveImage = useResolveImage;
exports.useBaseUrl = useBaseUrl;
const react_1 = require("react");
const react_components_1 = require("@grandlinex/react-components");
const GlobalContext_1 = require("@/context/GlobalContext");
function useResolveImage() {
    const context = (0, GlobalContext_1.useGlobalContext)();
    return (0, react_1.useCallback)((url) => {
        if (url?.startsWith('/api/')) {
            return `${context.api}${url}`;
        }
        return url;
    }, [context.api]);
}
var StaticURL;
(function (StaticURL) {
    StaticURL["MAP_TILE_PATH"] = "/api/map/t/";
    StaticURL["MAP_PIN_PATH"] = "/api/map/i/";
    StaticURL["RESOURCE_MEDIA"] = "/api/resource/media/e/";
    StaticURL["ATTACHMENT"] = "/api/attachment/e/";
})(StaticURL || (exports.StaticURL = StaticURL = {}));
function useBaseUrl(selPath, apend) {
    const img = useResolveImage();
    const meta = (0, react_1.useMemo)(() => {
        return (0, react_components_1.getDocumentMeta)('REACT_APP_VERSION') === '0.0.0';
    }, []);
    return (0, react_1.useMemo)(() => {
        const p = selPath + (apend || '');
        if (meta) {
            return img(p);
        }
        return p;
    }, [apend, img, meta, selPath]);
}
