"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class WebEmitter {
    events;
    constructor() {
        this.events = new Map();
    }
    on(name, listener) {
        if (!this.events.has(name)) {
            this.events.set(name, []);
        }
        this.events.get(name)?.push(listener);
    }
    removeListener(name, listenerToRemove) {
        if (!this.events.has(name)) {
            throw new Error(`Can't remove a listener. Event "${name}" doesn't exits.`);
        }
        const filterListeners = (listener) => listener !== listenerToRemove;
        const ev = this.events.get(name)?.filter(filterListeners) || [];
        this.events.set(name, ev);
    }
    emit(name, data) {
        if (this.events.has(name)) {
            const fireCallbacks = (callback) => {
                callback(data);
            };
            this.events.get(name)?.forEach(fireCallbacks);
        }
    }
}
exports.default = WebEmitter;
