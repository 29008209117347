"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FullImage;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_dom_1 = require("react-dom");
function FullImage(props) {
    const { className, style, tooltip, full, cover, contain, scaleDown } = props;
    const [max, setMax] = (0, react_1.useState)(false);
    const styleDX = (0, react_1.useMemo)(() => {
        const ax = {
            ...style,
        };
        if (contain) {
            ax.objectFit = 'contain';
        }
        if (cover) {
            ax.objectFit = 'cover';
        }
        if (scaleDown) {
            ax.objectFit = 'scale-down';
        }
        if (full) {
            ax.cursor = 'zoom-in';
        }
        return ax;
    }, [contain, cover, full, style]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        tooltip ? (react_1.default.createElement(react_components_1.Tooltip, { text: react_1.default.createElement("img", { loading: "lazy", className: className, alt: "", ...props, style: {
                    objectFit: styleDX.objectFit,
                }, height: 300, width: 400 }) },
            react_1.default.createElement("img", { loading: "lazy", className: (0, react_components_1.cnx)([!!full, 'glx-pointer'], className), alt: "", ...props, style: styleDX, onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setMax(true);
                } }))) : (react_1.default.createElement("img", { loading: "lazy", className: (0, react_components_1.cnx)([!!full, 'glx-pointer'], className), alt: "", ...props, style: styleDX, onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                setMax(true);
            } })),
        max &&
            (0, react_dom_1.createPortal)(react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, center: true, style: {
                    cursor: 'zoom-out',
                }, className: "img-fullscreen-container", onClick: (e) => {
                    e.stopPropagation();
                    setMax(false);
                } },
                react_1.default.createElement("img", { loading: "lazy", className: (0, react_components_1.cnx)(className), alt: "", ...props, width: undefined, height: undefined, style: { ...styleDX, cursor: 'zoom-out' } })), document.body)));
}
