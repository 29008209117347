"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransFcRaw = TransFcRaw;
const react_components_1 = require("@grandlinex/react-components");
function TransFcRaw({ sKey, el, children, fallback, langCode, force, }) {
    if (el && langCode === 'de' && (el[`${sKey}_de`] || force)) {
        return el[`${sKey}_de`];
    }
    if (el && langCode === 'en' && (el[sKey] || force)) {
        return el[sKey];
    }
    if (el && el[sKey]) {
        return el[sKey];
    }
    if (el && el[`${sKey}_de`]) {
        return el[`${sKey}_de`];
    }
    if (children) {
        return children;
    }
    if (fallback) {
        return 'Unknown';
    }
    return null;
}
class XLang extends react_components_1.GLang {
    doneSet = new Set();
    constructor(langDat) {
        super(langDat);
        window.xlang = this;
    }
    resGet(el, sKey, fallBack) {
        return TransFcRaw({
            el,
            sKey,
            langCode: this.code,
            force: false,
            fallback: true,
            children: fallBack,
        });
    }
    get(key) {
        if (!this.doneSet.has(key)) {
            this.doneSet.add(key);
        }
        return super.get(key);
    }
    getUnused() {
        const uSet = new Set(this.map.keys());
        for (const key of this.doneSet) {
            uSet.delete(key);
        }
        return uSet;
    }
}
exports.default = XLang;
