"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = UserTooltip;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const GlobalContext_1 = require("@/context/GlobalContext");
function UserTooltip({ user, children, userID, }) {
    const context = (0, GlobalContext_1.useGlobalContext)();
    const [remoteData] = (0, react_components_1.useQData)(async () => {
        if (!userID) {
            return null;
        }
        const { data } = await context.getAuthorDetail([
            {
                id: userID,
            },
        ]);
        return data?.[0] ?? null;
    });
    const u = (0, react_1.useMemo)(() => {
        if (user) {
            return user;
        }
        if (remoteData) {
            return remoteData;
        }
        return null;
    }, [user, remoteData]);
    if (!u) {
        return children;
    }
    return (react_1.default.createElement(react_components_1.Tooltip, { className: "glx-pointer", text: react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, className: "glx-p-2" },
            react_1.default.createElement(react_components_1.Grid, { flex: true, className: "tooltip-container", style: u.banner
                    ? {
                        backgroundImage: `url(${u.banner})`,
                    }
                    : {
                        backgroundImage: `linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 50%)`,
                    } },
                react_1.default.createElement("img", { alt: u.author_name, src: u.avatar })),
            react_1.default.createElement("div", { style: {
                    fontWeight: 'bold',
                    fontSize: '18pt',
                } }, u.author_name)) }, children ?? react_1.default.createElement("span", null, u.author_name)));
}
